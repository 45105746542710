/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

export default function FlagBanner({sblogin,SellerLogin,logValue}) {
    const [message, setMessage] = useState('');
    const [isTextExpanded, setIsTextExpanded] = useState(false);
    const [isHtmlMessage, setIsHtmlMessage] = useState(false);

    const [maxLength, setMaxLength] = useState(30); // Default for mobile

    const toggleText = () => setIsTextExpanded((prev) => !prev);

    useEffect(() => {
        // Set maxLength based on screen size (mobile vs desktop)
        const updateMaxLength = () => {
            if (window.innerWidth >= 768) {
                setMaxLength(100); // For medium and larger screens
            } else {
                setMaxLength(30); // For mobile screens
            }
        };

        updateMaxLength(); // Initial check
        window.addEventListener('resize', updateMaxLength); // Listen to resize events

        // Clean up the event listener on unmount
        return () => window.removeEventListener('resize', updateMaxLength);
    }, []);

    function truncateHtml(html, maxLength) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        let truncatedText = tempDiv.textContent || tempDiv.innerText || '';
        if (truncatedText.length > maxLength) {
            truncatedText = truncatedText.substring(0, maxLength) + '...';
        }

        return truncatedText;
    }


    // Determine whether to show full or truncated message
    const displayMessage =
        !isHtmlMessage && !isTextExpanded && message.length > maxLength
            ? message.substring(0, maxLength) + '...'
            : message;

    const buttonText = isTextExpanded ? 'Read less' : 'Read more';


    useEffect(() =>{
        if(sblogin===true || SellerLogin=== true){
            setMessage(
                `We're currently upgrading our platform and will be back online as quickly as possible. For urgent assistance, you can contact us at  <a href="support@indy.auction" target="_blank" rel="noopener noreferrer" class="highlight-link">customer support</a> .Thank you for your patience and understanding. Please try again shortly.`
            );
            setIsHtmlMessage(true); // Indicate that the message contains HTML
        }
        // If logValue is true, start the 2-minute timer
        if (logValue) {
            setMessage(`We're currently upgrading our platform and will be back online as quickly as possible. For urgent assistance, you can contact us at customer support <a href="support@indy.auction" target="_blank" rel="noopener noreferrer" class="highlight-link">customer support</a>. Thank you for your patience and understanding. Please try again shortly.`);
            setIsHtmlMessage(true); 
        }
        
         
    },[logValue])

    
    return (
       
        <div>
            <div className={`fixed  bg-[#DDD1D5] text-center text-[#282828]
             text-[14px] leading-[17px] font-medium font-Medium w-full top-[2.5rem] px-2 py-4 mb-5 z-[9999]`}>
                <div className={`justify-center items-center md:flex flex flex-wrap   `}>
                    {isHtmlMessage ? (
                        <p
                            className="text-[#282828]"
                            dangerouslySetInnerHTML={{
                                __html: isTextExpanded
                                    ? message
                                    : truncateHtml(message, maxLength),
                            }}
                        ></p>
                    ) : (
                        <p className="text-[#282828]">{displayMessage}</p>
                    )}
                    {isHtmlMessage && message.length > maxLength && (
                        <button
                            onClick={toggleText}
                            className="text-[#282828] underline"
                        >
                            {buttonText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

